import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {
  EditorOnlyRoute,
  HomeScreen,
  LoginScreen,
  DocumentsScreen,
  DocumentsStatsScreen,
  DocumentEditorScreen,
  ArchivedDocumentsScreen,
  PageNotFoundScreen,
} from "../screens";
import { getCurrentUser, isAdmin, isArchitect } from "../store";

const DocumentRouter = (props) => {
  const user = useSelector(getCurrentUser);
  return (
    <Routes>
      <Route path="/" index element={<DocumentsScreen />} />
      <Route
        path="/stats"
        index
        element={<EditorOnlyRoute Component={DocumentsStatsScreen} />}
      />
      <Route
        path="/archives"
        index
        element={<EditorOnlyRoute Component={ArchivedDocumentsScreen} />}
      />
      <Route path="/:documentId" index element={<DocumentEditorScreen />} />
      {(isAdmin(user) || isArchitect(user)) && (
        <Route
          path="/A/:documentId"
          index
          element={<DocumentEditorScreen isAdminCorrectionMode={true} />}
        />
      )}
    </Routes>
  );
};
const MainRouter = (props) => {
  return (
    <HomeScreen>
      <Routes>
        <Route path="/D/*" index element={<DocumentRouter />} />
        <Route path="/" element={<Navigate replace to="/D" />} />
      </Routes>
    </HomeScreen>
  );
};

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginScreen />} exact />
        <Route path="/page-not-found" element={<PageNotFoundScreen />} exact />
        <Route path="/*" element={<MainRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
