import { put, takeEvery, select } from "redux-saga/effects";
import {
  SagaRegistry,
  requestHandler,
  setAlertAndLoading,
  axios,
  getDateInFormat,
  getDateFullYearFormat,
  getDateTimeInFormat,
  dateSorter,
  getUserDetails,
} from "../common";
import { DocEndPoints } from "./endpoints";
import { docActions } from "./slice";
import { saveAs } from "file-saver";

const fetchS3ObjectAsBlob = (url, type) => {
  return fetch(url, {
    method: "GET",
    headers: axios.getAuthTokenHeader(),
  }).then((_) => _.blob());
};
const mapContent = (departments, types, classifications, statusList, _) => {
  const content = { ..._, more: "Info", key: _.id };
  content.updated_at = getDateInFormat(content.updated_at);
  content.created_at = getDateInFormat(content.created_at);
  getUserDetails(content, "creator");
  getUserDetails(content, "updater");
  if (Array.isArray(departments)) {
    const dept = departments.find((_) => _.id === content.department_name);
    if (dept) content.department_name = dept;
  }
  if (Array.isArray(types)) {
    const type = types.find((_) => _.id === content.doc_type);
    if (type) content.doc_type = type;
  }
  if (Array.isArray(statusList)) {
    const status = statusList.find((_) => _.id === content.status);
    if (status) content.status = status;
  }
  if (Array.isArray(classifications)) {
    const classif = classifications.find(
      (_) => _.id === content.classification
    );
    if (classif) content.classification = classif;
  }
  content.doc_linkname = `${content.doc_id}-${content.name}`;
  content.doc_link = `/D/${content.guid}`;
  content.doc_history = [];
  content.change_history = [];
  return content;
};
const fetchDepts = function* () {
  yield requestHandler(
    function* () {
      let res = yield axios.get("/consoles/department_list");
      res = (res.department_list || []).map((_) => ({ label: _, id: _ }));
      yield put(docActions.setDepts(res));
    },
    "Failed to Fetch Departments",
    false
  );
};
const fetchDocsClassifications = function* () {
  yield requestHandler(
    function* () {
      let res = yield axios.get("/centrals/doc_classi_list");
      res = Object.keys(res.types || {}).map((_) => ({
        label: _,
        attribute: _,
        id: _,
      }));
      yield put(docActions.setDocClassifications(res));
    },
    "Failed to Fetch Classifications",
    false
  );
};
const fetchDocTemplates = function* () {
  yield requestHandler(
    function* () {
      const templates = [
        "Document Control Policy",
        "Disciplinary Action for InfoSec Violation Procedure",
        "Secure Development Principle",
        "Employee Handbook",
        "Customer Service Manual",
        "Information Security Policy",
        "Risk Management Plan",
        "Privacy Compliance Process",
      ];
      const res = (templates || []).map((_) => ({ label: _, id: _ }));
      yield put(docActions.setDocTemplates(res));
    },
    "Failed to Fetch Document Templates",
    false
  );
};
const fetchDocTypes = function* () {
  yield requestHandler(
    function* () {
      let res = yield axios.get("/centrals/doc_type_list");
      res = Object.keys(res.types || {}).map((_) => ({
        label: _,
        attribute: _,
        id: _,
      }));
      yield put(docActions.setDocTypes(res));
    },
    "Failed to Fetch Document Types",
    false
  );
};
const fetchDocStatusList = function* () {
  yield requestHandler(
    function* () {
      let res = yield axios.get("/documents/status_list");
      res = (res.statuses || []).map((_) => ({ label: _, id: _ }));
      yield put(docActions.setDocStatus(res));
    },
    "Failed to Fetch Document Status List",
    false
  );
};
const createDocFromBlueprint = function* ({ payload }) {
  yield requestHandler(function* () {
    yield axios.post("/documents/create_document_from_blueprint", payload);
    yield put(docActions.fetchDocuments());
    yield setAlertAndLoading(null, {
      message: "Document Created Successfully",
    });
  }, "Document Creation Failed");
};
const createAllDocs = function* () {
  yield requestHandler(function* () {
    yield put(docActions.setCurrentAPI(DocEndPoints.CREATE_DOC));
    yield axios.post("/documents/create_all_doc", {});
    yield setAlertAndLoading(null, {
      message: "Documents Created Successfully",
    });
    yield put(docActions.setCurrentAPI(null));
    yield put(docActions.fetchDocuments());
  }, "Document Creation Failed");
};
const uploadDocuments = function* ({ payload }) {
  yield requestHandler(function* () {
    for (let i = 0; i < payload.length; i++) {
      const body = payload[i];
      yield axios.post("/documents/doc_attachment", body, {
        ...axios.getFormDataContentType(),
      });
    }
    yield put(docActions.fetchDocuments());
    yield setAlertAndLoading(null, {
      message: "Documents Uploaded Successfully",
    });
  }, "Document Upload Failed");
};
const downloadDocuments = function* ({ payload }) {
  yield requestHandler(function* () {
    const { document, credentials } = payload;
    try {
      // yield put(docActions.setDownloadStatus({ type: 'download', value: true }))
      let path = `/documents/${document.id}`;
      if (credentials && credentials.length > 0) {
        path += `?pwd=${credentials}`;
      }
      const res = yield fetch(axios.getFullPath(path), {
        method: "GET",
        headers: axios.getAuthTokenHeader(),
      });
      if (res.status === 200) {
        const blob = yield res.blob();
        let filename = String(document.name || "document");
        filename = filename
          .replace(".pdf", "")
          .replace(new RegExp(" ", "g"), "_");
        filename = `${document.doc_id}-${filename} ${document.version}.pdf`;
        saveAs(blob, filename);
        // yield put(docActions.setDownloadStatus({ type: 'download', value: null }))
        yield setAlertAndLoading(null, {
          message: "Document downloaded successfully",
        });
      } else {
        throw res;
      }
    } catch (error) {
      yield setAlertAndLoading(null, {
        success: false,
        message: "Document Download Failed",
      });
    }
  }, "Document Download Failed");
};
const shareDocuments = function* ({ payload }) {
  yield requestHandler(function* () {
    // yield put(docActions.setDownloadStatus({ type: 'share', value: true }))
    const body = {
      document_id: payload.id,
      email_ids: payload.emails,
      credential: payload.credential,
    };
    yield axios.post("/documents/report_doc_email", body);
    // yield put(docActions.setDownloadStatus({ type: 'share', value: null }))
    yield setAlertAndLoading(null, { message: "Document Shared Successfully" });
  }, "Document Shared Failed");
};
const archiveDocuments = function* ({ payload }) {
  yield requestHandler(function* () {
    yield put(docActions.setDeleteInProgress(true));
    try {
      yield put(docActions.removeBlueprint(payload));
      yield axios.post("/documents/archive_document", {
        document_id: payload.id,
      });
      yield put(docActions.setDeleteInProgress(false));
      yield setAlertAndLoading(null, {
        message: "Document Archived Sucessfully ",
      });
    } catch (error) {
      yield put(docActions.setDeleteInProgress(false));
      throw error;
    }
  }, "Document Achive Failed");
};
const _updateDocument = (body) => {
  return axios.put("/documents/update_document", body);
};
const updateDocument = function* ({ payload }) {
  yield requestHandler(function* () {
    const { id, document, updateHistory, noMsg } = payload;
    yield _updateDocument({ document_id: id, document: document });
    if (updateHistory) {
      const body = { document_id: payload.id, ...updateHistory };
      yield axios.post("/documents/add_doc_history", body);
    }
    if (noMsg !== true) {
      yield put(docActions.fetchDocument({ documentId: id }));
      yield setAlertAndLoading(null, {
        message: "Document updated successfully",
      });
    }
  }, "Document update failed");
};
const updateDocumentWithOutAudit = function* ({ payload }) {
  yield requestHandler(function* () {
    const { id, document, onComplete } = payload;
    yield axios.put("/documents/update_document_without_audit", {
      document_id: id,
      document: document,
    });
    yield setAlertAndLoading(null, {
      message: "Document updated successfully",
    });
    onComplete && onComplete();
  }, "Document update failed");
};
const unarchiveDocuments = function* ({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload;
    yield put(docActions.setDeleteInProgress(true));
    try {
      yield put(docActions.removeBlueprint(payload));
      yield _updateDocument({ document_id: id, document: { status: "draft" } });
    } catch (error) {
      throw error;
    }
    yield put(docActions.setDeleteInProgress(false));
    yield setAlertAndLoading(null, {
      message: "Document Unarchived Successfully",
    });
    yield put(docActions.fetchDocuments());
  }, "Document UnAchive Failed");
};
const deleteDocuments = function* ({ payload }) {
  yield requestHandler(function* () {
    yield put(docActions.setDeleteInProgress(true));
    try {
      yield put(docActions.removeBlueprint(payload));
      let body = { document_id: payload.id };
      yield axios.post("/documents/delete_document", body);
      yield setAlertAndLoading(null, { message: "Document has been Deleted" });
    } catch (error) {
      throw error;
    }
    yield put(docActions.setDeleteInProgress(false));
    yield put(docActions.fetchDocuments());
  }, "Failed to delete document");
};
const sendDocumentRequest = function* ({ payload }) {
  yield requestHandler(function* () {
    let res, message;
    if (payload.reviewer_id) {
      res = yield axios.post("/documents/send_document_for_review", payload);
      message = "Document Sent for Review";
    } else {
      res = yield axios.post("/documents/send_document_for_approve", payload);
      message = "Document Sent for Approval";
    }
    if (res.errors) {
      message =
        "Unknown Error. Unable to send document for " +
        (payload.reviewer_id ? "Review" : "Approval");
    } else {
      yield put(docActions.fetchDocument({ documentId: payload.document_id }));
    }
    yield setAlertAndLoading(null, { success: !Boolean(res.errors), message });
  }, "Failed to send");
};
const updateDocumentStatus = function* ({ payload }) {
  yield requestHandler(function* () {
    const body = {
      document_id: payload.id,
      document: { status: payload.status },
    };
    yield axios.put("/documents/change_document_status", body);
    yield put(docActions.fetchDocument({ documentId: payload.id }));
    yield setAlertAndLoading(null, {
      message: "Document status has been updated",
    });
  }, "Failed to update document status");
};
const fetchDocuments = function* () {
  yield requestHandler(function* () {
    const departments = yield select((state) => state.document.departments),
      types = yield select((state) => state.document.docTypes),
      statusList = yield select((state) => state.document.docStatusList),
      classifications = yield select(
        (state) => state.document.docClassfications
      );
    let res = yield axios.get("/documents/content_bp_list");
    res = res.blueprint_list ? res.blueprint_list : res;
    res = Array.isArray(res) ? res : [];
    // res.sort(dateSorter.bind(null, 'updated_at'))
    // Sort by doc_id in descending order- recent one at top
    res.sort((a, b) => {
      if (a.doc_id < b.doc_id) return 1;
      if (a.doc_id > b.doc_id) return -1;
      return 0;
    });
    const list = [],
      archives = [];
    (res || []).forEach((_) => {
      let doc = mapContent(departments, types, classifications, statusList, _);
      if (doc.status.id === "archived") {
        doc.link = "/D/" + (doc.guid || doc.id);
        archives.push(doc);
      } else {
        list.push(doc);
      }
    });
    yield put(docActions.setDocuments({ list, archives }));
    yield put(docActions.setCurrentDoc(null));
  }, "Failed to Load Document List");
};
const fetchDocument = function* ({ payload }) {
  yield requestHandler(function* () {
    yield put(docActions.setCurrentDoc(null));
    const departments = yield select((state) => state.document.departments),
      types = yield select((state) => state.document.docTypes),
      classifications = yield select(
        (state) => state.document.docClassfications
      ),
      statusList = yield select((state) => state.document.docStatusList);
    let isIDNan = isNaN(Number(payload.documentId));
    let id = isIDNan ? payload.documentId : Number(payload.documentId);
    if (isIDNan) {
      id = yield axios.post("/documents/get_document_id", { guid: id });
      if (!id.id) {
        yield put(docActions.setCurrentDoc("NOTFOUND"));
        return;
      }
      id = id.id;
    }
    let res = yield axios.get("/documents/show_document?document_id=" + id);
    if (res) {
      res = mapContent(departments, types, classifications, statusList, res);
      let updateHistory = yield axios.post(
        "/documents/document_updated_audits",
        { document_id: id }
      );
      if (updateHistory && updateHistory.document_histories) {
        updateHistory = updateHistory.document_histories;
        updateHistory.sort(dateSorter.bind(null, "created_at"));
        res.change_history = updateHistory.map((_) => {
          let history = { ..._ };
          history.created_at = getDateFullYearFormat(history.created_at);
          getUserDetails(history, "creator");
          history.action = "Update";
          history.responsible = `${history.creator_name}, ${
            _.designation ? `${_.designation}, ` : ""
          }${_.department_name || ""}`;
          return history;
        });
      }
      let audit = yield axios.post("/documents/document_audits", {
        document_id: id,
      });
      if (audit && audit.audits) {
        audit = audit.audits;
        audit.sort(dateSorter.bind(null, "created_at"));
        res.doc_history = audit.map((_) => {
          let history = { ..._ };
          history.username = _.username || "";
          history.designation_function = `${
            _.designation ? `${_.designation}, ` : ""
          }${_.department_name || ""}`;
          history.created_at = getDateFullYearFormat(history.created_at);
          if (history.action === "update") {
            if (history.audited_changes && history.audited_changes.status) {
              if (history.audited_changes.status[0] === "archived") {
                history.action = "unachived";
              } else if (history.audited_changes.status[1] === "draft") {
                history.action = "revision";
              } else {
                history.action = history.audited_changes.status[1];
              }
            } else {
              history.action = null;
            }
          } else if (
            history.action === "create" ||
            history.action === "created"
          ) {
            history.action = "draft";
          }
          return history;
        });
        res.doc_history = res.doc_history.filter((_) => Boolean(_.action));
      }
      if (res.attachments && res.attachments[0]) {
        let url = res.attachments[0].evidence_url;
        res.attachments[0].evidence_url_full = axios.getFullPath(
          `/educators/s3_download_file?url=${url}`
        );
        res.attachments[0].blob = yield fetchS3ObjectAsBlob(
          res.attachments[0].evidence_url_full
        );
      }
      yield put(docActions.setCurrentDoc(res));
    }
  }, "Failed to fetch Document");
};
const addDocComment = function* ({ payload }) {
  yield requestHandler(function* () {
    const res = yield axios.post("/documents/comment", payload);
    yield put(docActions.fetchDocComments({ id: payload.document_id }));
    yield setAlertAndLoading(null, { message: "Comment added to document" });
  }, "Failed to add comment");
};
const fetchDocComments = function* ({ payload }) {
  yield requestHandler(function* () {
    let res = yield axios.get(
      "documents/doc_comment_list?document_id=" + payload.id
    );
    let comments = res.comments ? res.comments : res;
    comments = Array.isArray(comments) ? comments : [];
    comments.sort(dateSorter.bind(null, "updated_at"));
    comments = comments.map((comment) => {
      return {
        ...comment,
        updated_at: getDateTimeInFormat(comment.updated_at),
        isResolved: false,
      };
    });
    yield put(docActions.setDocComments(comments));
  }, "Failed to fetch comment");
};
const fetchDocStats = function* () {
  yield requestHandler(function* () {
    const res = yield axios.get("/documents/dashboard");
    yield put(docActions.setDocStats(res));
  }, "Failed to fetch stats");
};
const fetchBlueprintsByDeptType = function* ({ payload }) {
  yield requestHandler(function* () {
    let res = yield axios.post(
      "/centrals/contents_based_on_dept_type",
      payload
    );
    res = res.blueprint_list ? res.blueprint_list : res;
    res = Array.isArray(res) ? res : [];
    res = res.map((_) => {
      return { id: _.id, label: _.name };
    });
    yield put(docActions.setBlueprints(res));
  }, "Failed to Documents Blueprints");
};
const fetchNewDocId = function* ({ payload }) {
  yield requestHandler(function* () {
    const { doc_type, index } = payload;
    const body = { doc_id: doc_type, offset: index };
    const res = yield axios.post("/documents/get_doc_id_for_document", body);
    yield put(
      docActions.setNewDocumentIds({ doc_id: res.doc_id, index: index })
    );
  }, "Failed to fetch new document Id");
};
const fetchReviewers = function* () {
  yield requestHandler(function* () {
    let res = yield axios.get("/documents/reviewer_list");
    res = res.reviewer_list ? res.reviewer_list : res;
    res = Array.isArray(res) ? res : [];
    res = res.map((_) => {
      return { label: _[0], id: _[1] };
    });
    yield put(docActions.setReviewers(res));
  }, "Failed to fetch reviewers");
};
const fetchActivePresentation = function* () {
  yield requestHandler(function* () {
    let presentation = yield axios.get("/centrals/active_presentation_list");
    presentation = presentation.blueprint_list
      ? presentation.blueprint_list
      : presentation;
    presentation = Array.isArray(presentation) ? presentation : [];
    if (presentation.length === 0) {
      yield put(docActions.setActivePresentation("NA"));
      yield setAlertAndLoading(null, {
        success: false,
        message: "No Active presentation blueprint exist",
      });
      return;
    }
    presentation = presentation[0];
    yield put(docActions.setActivePresentation(presentation));
  }, "Failed to create docuement from presentation");
};
const createDocFromPresentation = function* ({ payload }) {
  yield requestHandler(function* () {
    let { document, sections } = payload;
    let presentation = yield axios.get("/centrals/active_presentation_list");
    presentation = presentation.blueprint_list
      ? presentation.blueprint_list
      : presentation;
    presentation = Array.isArray(presentation) ? presentation : [];
    if (presentation.length === 0) {
      yield setAlertAndLoading(null, {
        success: false,
        message: "No Active presentation blueprint exist",
      });
      return;
    }
    presentation = presentation[0];
    sections.forEach((sec) => {
      document[sec.attribute] = presentation[sec.attribute] || "";
    });
    document.presentation_id = presentation.id;
    document.page_config = presentation.page_config;
    yield axios.post("/documents/doc_attachment", document);
    yield put(docActions.fetchDocuments());
    yield setAlertAndLoading(null, {
      message: "Document Created successfully",
    });
  }, "Failed to create docuement from presentation");
};
const updateCommentResolution = function* ({ payload }) {
  yield requestHandler(function* () {
    let { commentId, documentId } = payload;
    const res = yield axios.post("/documents/mark_as_resolved_comment", {
      comment_id: commentId,
    });
    if (res.errors) {
      yield setAlertAndLoading(null, {
        success: false,
        message: "Failed to update comment status",
      });
      return;
    }
    yield put(docActions.fetchDocComments({ id: documentId }));
    yield setAlertAndLoading(null, { message: "Comment Marked as Resolved" });
  }, "Failed to create docuement from presentation");
};
SagaRegistry.register(function* docSaga() {
  yield takeEvery("document/fetchDepts", fetchDepts);
  yield takeEvery("document/fetchDocTemplates", fetchDocTemplates);
  yield takeEvery("document/fetchDocClassifications", fetchDocsClassifications);
  yield takeEvery("document/fetchDocTypes", fetchDocTypes);
  yield takeEvery("document/createDocFromBlueprint", createDocFromBlueprint);
  yield takeEvery("document/createAllDocs", createAllDocs);
  yield takeEvery("document/updateDocument", updateDocument);
  yield takeEvery("document/uploadDocuments", uploadDocuments);
  yield takeEvery("document/downloadDocuments", downloadDocuments);
  yield takeEvery("document/shareDocuments", shareDocuments);
  yield takeEvery("document/archiveDocuments", archiveDocuments);
  yield takeEvery("document/unarchiveDocuments", unarchiveDocuments);
  yield takeEvery("document/deleteDocuments", deleteDocuments);
  yield takeEvery("document/sendDocumentRequest", sendDocumentRequest);
  yield takeEvery("document/updateDocumentStatus", updateDocumentStatus);
  yield takeEvery("document/fetchDocStatusList", fetchDocStatusList);
  yield takeEvery("document/fetchDocuments", fetchDocuments);
  yield takeEvery("document/fetchDocument", fetchDocument);
  yield takeEvery("document/addDocComment", addDocComment);
  yield takeEvery("document/fetchDocComments", fetchDocComments);
  yield takeEvery("document/fetchDocStats", fetchDocStats);
  yield takeEvery("document/fetchNewDocId", fetchNewDocId);
  yield takeEvery(
    "document/fetchBlueprintsByDeptType",
    fetchBlueprintsByDeptType
  );
  yield takeEvery("document/fetchReviewers", fetchReviewers);
  yield takeEvery(
    "document/createDocFromPresentation",
    createDocFromPresentation
  );
  yield takeEvery("document/fetchActivePresentation", fetchActivePresentation);
  yield takeEvery("document/updateCommentResolution", updateCommentResolution);
  yield takeEvery(
    "document/updateDocumentWithOutAudit",
    updateDocumentWithOutAudit
  );
});
